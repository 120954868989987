<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-xl-5">
                            <h1 class="row">{{ activeLanguage.title }}</h1>
                        </div>
                        <div class="col-12 col-xl-7">
                            <div class="row btns">
                                <template v-if="manageOrdersPermissions">
                                    <b-button-group>
                                        <b-dropdown right no-caret>
                                            <template slot="button-content">
                                                <i class="fa fa-wrench" aria-hidden="true" style="margin-right: 4px;"></i>
                                                Settings
                                            </template>
                                            <b-dropdown-item to="/dispute/template/list">
                                                Template Editor
                                            </b-dropdown-item>
                                            <b-dropdown-item to="/dispute/configurator">
                                                Configurator
                                            </b-dropdown-item>
                                            <b-dropdown-item to="/dispute/employee/mail">
                                                Unrecognized emails
                                            </b-dropdown-item>
                                            <b-dropdown-item to="/dispute/employee/rules">
                                                Closing automation rules
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </b-button-group>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 40px;">
                <div class="col-12">
                    <form class="card row">
                        <div class="col-12">
                            <div class="card-header row">filters</div>
                            <div class="cord-body row" style="padding: 15px 0">
                                <div class="col-3">
                                    <div class="">
                                        <label for="email">Email</label>
                                      <input v-model="filter.email" type="text" class="form-control" id="email" @change="getGroups()" placeholder="Email">
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="">
                                        <label for="invoice">{{ activeLanguage.invoice }}</label>
                                      <input v-model="filter.invoice" type="text" class="form-control" id="invoice" @change="getGroups()" :placeholder="activeLanguage.invoice">
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="multiselect-custom">
                                        <div class="selectBox" @click="showCheckboxes" v-click-outside="closeCheckboxes">
                                            <label for="category" style="margin-bottom: 7px">{{ activeLanguage.categories.title }}</label>
                                            <select class="form-control" style="height: 35px" :placeholder="activeLanguage.categories.title">
                                                <option>{{selectedCategoriesText}}</option>
                                            </select>
                                        <div class="overSelect"></div>
                                        </div>
                                        <div id="checkboxes" v-show="showCheckbox">
                                            <div class="checkboxesInner">
                                                <label for="broken" @click="setCategory('broken')">
                                                    <input type="checkbox"  v-model="brokenCheckbox" />
                                                    {{ activeLanguage.categories.broken }}
                                                </label>
                                                    
                                                <label for="return" @click="setCategory('return')">
                                                    <input type="checkbox" v-model="returnCheckbox" />
                                                    {{ activeLanguage.categories.return }}
                                                </label>
                                                <label for="not-arrived" @click="setCategory('not-arrived')">
                                                    <input type="checkbox" v-model="notArrivedCheckbox" />
                                                    {{ activeLanguage.categories.notArrived }}
                                                </label>
                                                <label for="different" @click="setCategory('different')">
                                                    <input type="checkbox" v-model="differentCheckbox" />
                                                    {{ activeLanguage.categories.different }}
                                                </label>
                                                <label for="other" @click="setCategory('other')">
                                                    <input type="checkbox" v-model="otherCheckbox" />
                                                    {{ activeLanguage.categories.other }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3" style="margin-top: 10px">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="input-group">
                                                <label class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" v-model="filter.isResolved" @change="onFilterSubmit('resolved')">
                                                    <span class="custom-control-indicator" style="top: .2rem;"></span>
                                                    <span style="vertical-align: middle;">{{ activeLanguage.resolved }}</span>
                                                </label>
                                            </div>
                                            <div class="input-group">
                                                <label class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" v-model="filter.hasActiveTasks"  @change="onFilterSubmit('active')">
                                                    <span class="custom-control-indicator" style="top: .2rem;"></span>
                                                    <span style="vertical-align: middle;">{{ activeLanguage.action }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-12">
                    <div class="row">
                        <table class="table" style="margin-top: 40px;">
                            <thead class="thead-dark">
                            <tr>
                                <th scope="col">#id</th>
                                <th scope="col" class="text-center">Status</th>
                                <th scope="col" width="98px">{{ activeLanguage.groupTable.date }}</th>
                                <th scope="col">{{ activeLanguage.groupTable.reason }}</th>
                                <th scope="col">{{ activeLanguage.groupTable.inv }}</th>
                                <th scope="col">{{ activeLanguage.groupTable.from }}</th>
                                <th scope="col" v-if="manageOrdersPermissions">{{ activeLanguage.groupTable.to }}</th>
                                <th scope="col" v-if="manageOrdersPermissions">{{ activeLanguage.groupTable.owner }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(dispute, index) in disputes"
                                v-if="!dispute.hidden"
                                @click="goToDispute($event, dispute, index)"
                                style="cursor: pointer;"
                                :class="{ 'selected' : selectedIndex === index}"
                            >
                                <td>#{{ dispute.id }}</td>
                                <td class="text-center status-icon">
                                    <i
                                        class="fa"
                                        :class="dispute.icon"
                                        aria-hidden="true"
                                        v-b-tooltip.hover.right.title="dispute.tip"
                                    ></i>
                                </td>
                                <td>{{ dispute.date }}</td>
                                <td>{{ dispute.category }}</td>
                                <td><div class="overflow">{{ dispute.invoice }}</div></td>
                                <td><div class="overflow">{{ dispute.customerEmail }}</div></td>
                                <td v-if="manageOrdersPermissions"><div class="overflow">{{ dispute.clientEmail }}</div></td>
                                <td v-if="manageOrdersPermissions"><div class="overflow">{{ dispute.ownerUsername }}</div></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
              <div class="col-12">
                <div style="height: 35px;"></div>
                <b-pagination
                    align="center"
                    :prev-text="paginationLanguage.pagPrev"
                    :next-text="paginationLanguage.pagNext"
                    :limit="6"
                    @input="getGroups()"
                    :total-rows="pagData.maxItems"
                    v-model="pagData.currentPage"
                    :per-page="20"
                    v-if="pagData.maxItems > 20"
                    :disabled="false"
                />
                <PaginationCount
                    :pagData="pagData"
                    :selection="csvIds"
                    :activeLanguage="paginationLanguage.pagTxt"
                />
              </div>
            </div>
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex";
	import Datepicker from 'vuejs-datepicker'
	import moment from 'moment';
    import Pagination from '@/components/common/reusable/Pagination.vue'
    import PaginationCount from '@/components/common/reusable/PaginationCount.vue'
    import ClientItemTemplate from '@/components/disputeView/DisputeAutocompleteItem.vue'

	export default {
		name: "DisputeEmployeeShowGroups",
		data() {
			return {
				disputes: [],
				filter: {},
                pagData: {
                currentPage: 1,
                maxItems: 0,
                },
                csvIds: [],
                autocompleteDataInv: [],
                autocompletePickInv: null,
				autocompleteDataMail: [],
				autocompletePickMail: null,
				itemTemplate: ClientItemTemplate,
                selectedCategories : [],
                selectedCategoriesText: '',
                showCheckbox: false,
                brokenCheckbox: false,
                returnCheckbox: false,
                retournedCheckbox: false,
                notArrivedCheckbox: false,
                differentCheckbox: false,
                otherCheckbox: false,
                selectedIndex: null
			}
		},
		methods: {
            showCheckboxes() {
                this.showCheckbox = !this.showCheckbox
            },
            closeCheckboxes() {
                this.showCheckbox = false
            },
            setCategory(type) {
                this.selectedCategories = this.selectedCategories ? this.selectedCategories : this.selectedCategories = []
                let formattedText = ''
                
                switch(true) {
                    case !this.brokenCheckbox && type === 'broken' :
                        this.selectedCategories.push('broken')
                        this.selectedCategories.push('gebroken')
                        this.selectedCategoriesText = this.selectedCategoriesText === this.activeLanguage.categories.title ? this.selectedCategoriesText = `${this.activeLanguage.categories.broken}` : this.selectedCategoriesText = this.selectedCategoriesText + `, ${this.activeLanguage.categories.broken}`
                        this.brokenCheckbox = true
                        break;
                    case !this.returnCheckbox && type === 'return' :
                        this.returnCheckbox = true
                        this.selectedCategories.push('return')
                        this.selectedCategories.push('retour')
                        this.selectedCategories.push('geretourneerd')
                        this.selectedCategoriesText = this.selectedCategoriesText === this.activeLanguage.categories.title ? this.selectedCategoriesText = `${this.activeLanguage.categories.return}` : this.selectedCategoriesText = this.selectedCategoriesText + `, ${this.activeLanguage.categories.return}`
                        break;
                    case !this.notArrivedCheckbox && type === 'not-arrived' :
                        this.notArrivedCheckbox = true
                        this.selectedCategories.push('not-arrived')
                        this.selectedCategories.push('niet ontvangen')
                        this.selectedCategoriesText = this.selectedCategoriesText === this.activeLanguage.categories.title ? this.selectedCategoriesText = `${this.activeLanguage.categories.notArrived}` : this.selectedCategoriesText = this.selectedCategoriesText + `, ${this.activeLanguage.categories.notArrived}`
                        break;
                    case !this.differentCheckbox && type === 'different' :
                        this.differentCheckbox = true
                        this.selectedCategories.push('different')
                        this.selectedCategories.push('niet besteld')
                        this.selectedCategoriesText = this.selectedCategoriesText === this.activeLanguage.categories.title ? this.selectedCategoriesText = `${this.activeLanguage.categories.different}` : this.selectedCategoriesText = this.selectedCategoriesText + `, ${this.activeLanguage.categories.different}`
                        break;
                    case !this.otherCheckbox && type === 'other' :
                        this.otherCheckbox = true
                        this.selectedCategories.push('other')
                        this.selectedCategories.push('anders')
                        this.selectedCategoriesText = this.selectedCategoriesText === this.activeLanguage.categories.title ? this.selectedCategoriesText = `${this.activeLanguage.categories.other}` : this.selectedCategoriesText = this.selectedCategoriesText + `, ${this.activeLanguage.categories.other}`
                        break;
                    case this.brokenCheckbox && type === 'broken' :
                        this.selectedCategories = this.selectedCategories.filter(e => e !== 'broken')
                        this.selectedCategories = this.selectedCategories.filter(e => e !== 'gebroken')
                        this.selectedCategoriesText = this.selectedCategories.length && this.selectedCategoriesText !== this.activeLanguage.categories.title ? this.selectedCategoriesText : this.activeLanguage.categories.title
                        formattedText = this.selectedCategoriesText.includes(`${this.activeLanguage.categories.broken}`) ? this.selectedCategoriesText.replace(`${this.activeLanguage.categories.broken}, `, '') : this.selectedCategoriesText.replace(`${this.activeLanguage.categories.broken}`, '')
                        this.selectedCategoriesText = formattedText
                        this.brokenCheckbox = false
                        break;
                    case this.returnCheckbox && type === 'return' :
                        this.returnCheckbox = false
                        this.selectedCategories = this.selectedCategories.filter(e => e !== 'return')
                        this.selectedCategories = this.selectedCategories.filter(e => e !== 'retour')
                        this.selectedCategories = this.selectedCategories.filter(e => e !== 'geretourneerd')
                        this.selectedCategoriesText = this.selectedCategories.length && this.selectedCategoriesText !== this.activeLanguage.categories.title ? this.selectedCategoriesText : this.activeLanguage.categories.title
                        formattedText = this.selectedCategoriesText.includes(`${this.activeLanguage.categories.return}`) ? this.selectedCategoriesText.replace(`${this.activeLanguage.categories.return}, `, '') : this.selectedCategoriesText.replace(`${this.activeLanguage.categories.return}`, '')
                        this.selectedCategoriesText = formattedText
                        break;
                    case this.notArrivedCheckbox && type === 'not-arrived' :
                        this.notArrivedCheckbox = false
                        this.selectedCategories = this.selectedCategories.filter(e => e !== 'not-arrived')
                        this.selectedCategories = this.selectedCategories.filter(e => e !== 'niet ontvangen')
                        this.selectedCategoriesText = this.selectedCategories.length && this.selectedCategoriesText !== this.activeLanguage.categories.title ? this.selectedCategoriesText : this.activeLanguage.categories.title
                        formattedText = this.selectedCategoriesText.includes(`${this.activeLanguage.categories.notArrived}`) ? this.selectedCategoriesText.replace(`${this.activeLanguage.categories.notArrived}, `, '') : this.selectedCategoriesText.replace(`${this.activeLanguage.categories.notArrived}`, '')
                        this.selectedCategoriesText = formattedText
                        break;
                    case this.differentCheckbox && type === 'different' :
                        this.differentCheckbox = false
                        this.selectedCategories = this.selectedCategories.filter(e => e !== 'different')
                        this.selectedCategories = this.selectedCategories.filter(e => e !== 'niet besteld')
                        this.selectedCategoriesText = this.selectedCategories.length && this.selectedCategoriesText !== this.activeLanguage.categories.title ? this.selectedCategoriesText : this.activeLanguage.categories.title
                        formattedText = this.selectedCategoriesText.includes(`${this.activeLanguage.categories.different}`) ? this.selectedCategoriesText.replace(`${this.activeLanguage.categories.different}, `, '') : this.selectedCategoriesText.replace(`${this.activeLanguage.categories.different}`, '')
                        this.selectedCategoriesText = formattedText
                        break;
                    case this.otherCheckbox && type === 'other' :
                        this.otherCheckbox = false
                        this.selectedCategories = this.selectedCategories.filter(e => e !== 'other')
                        this.selectedCategories = this.selectedCategories.filter(e => e !== 'anders')
                        this.selectedCategoriesText = this.selectedCategories.length && this.selectedCategoriesText !== this.activeLanguage.categories.title ? this.selectedCategoriesText : this.activeLanguage.categories.title
                        formattedText = this.selectedCategoriesText.includes(`${this.activeLanguage.categories.other}`) ? this.selectedCategoriesText.replace(`${this.activeLanguage.categories.other}, `, '') : this.selectedCategoriesText.replace(`${this.activeLanguage.categories.other}`, '')
                        this.selectedCategoriesText = formattedText
                        break;
                }
                this.getGroups()
            },
			renderDisputeIcon({ status }) {
				switch (status) {
                    case 'resolved': return { icon: 'fa fa-check-circle', tip: this.activeLanguage.iconResolved }
                    case 'created': return { icon: 'fa fa-play-circle', tip: this.activeLanguage.iconCreated }
                    case 'no_reaction': return { icon: 'fa fa-clock-o', tip: this.activeLanguage.iconNoReaction }
                    case 'reaction': return { icon: 'fa fa-bell', tip: this.activeLanguage.iconReaction }
                }
            },
			checkIfAutocompleteIsEmpty(val) {
				if (val.length === 0) { this.onFilterSubmit() }
            },
            autocompleteDataIsSelected(val, type) {
	            disputeAxios
		            .post(`/api/employee-dispute/groups-autocomplete/confirm`, val)
		            .then(({data}) => {
			            this.filter = {}
			            this.formatDisputes(data)
                    })
		            .catch(err => console.error(err))
            },
			getLabel (item) {
				return !!item ? item.email || item.invoice : ''
			},
            formatDisputes(data) {
	            this.disputes = data.map(
	            	dispute => {
		                let { icon, tip } = this.renderDisputeIcon(dispute)
                        dispute.icon = icon
                        dispute.tip = tip
                        dispute.date = moment(dispute.createdAt.date).format('DD-MM-YYYY')
                        return dispute
                    }
	            )
            },
			goToDispute(e, dispute, index) {
                this.selectedIndex = index
                window.open(`/order/${dispute.invoice}?openDispute=true&id=${dispute.id}`, '_blank');
            },
			onFilterSubmit(type = '') {
				let oldFilter = this.filter
				this.autocompleteDataMail = []
				this.autocompletePickMail = null
				this.autocompleteDataInv = []
				this.autocompletePickInv = null
                if (type === 'resolved') {
	                this.filter.isResolved = !!oldFilter.isResolved
                } else if (type === 'active') {
	                this.filter.hasActiveTasks = !!oldFilter.hasActiveTasks
                }
				this.getGroups()
			},
			getGroups() {
                let page = this.pagData.currentPage
                let categories = this.selectedCategories
				let params = {
                    categories,
					...this.filter,
                    page,
                    perPage: 20
				}

                disputeAxios
					.get('/api/employee-dispute/groups', { params })
					.then(({ data }) => {
					  this.pagData.maxItems = data.total_items
						this.formatDisputes(data.items_list)
                        let oldParams = this.savePrevCategoriesParams(params)
						sessionStorage.setItem('disputeFilterParams', JSON.stringify(oldParams))
                    })
					.catch(err => console.error(err))
			},
			onCreateDispute({resolved, id}) {
				if (!resolved) { this.$router.push(`/dispute/employee/create/${id}`) }
			},
			onDelete(dispute) {
				disputeAxios
					.patch(`/api/employee-dispute/group/hide/${dispute.id}`)
					.then(({data}) => dispute.hidden = data.code === 2004)
					.catch(err => console.error(err))
			},
			resolveToggle(dispute) {
				disputeAxios
					.patch(`/api/employee-dispute/group/${dispute.resolved ? 'reopen' : 'resolve'}/${dispute.id}`)
					.then(({data}) => {
						if (data.code === 2010) { dispute.resolved = true }
						else if (data.code === 2012) { dispute.resolved = false }
					})
					.catch(err => console.error(err))
			},
            savePrevCategoriesParams(params) {
                params.brokenCheckbox = this.brokenCheckbox
                params.returnCheckbox = this.returnCheckbox
                params.retournedCheckbox = this.retournedCheckbox
                params.notArrivedCheckbox = this.notArrivedCheckbox
                params.differentCheckbox = this.differentCheckbox
                params.otherCheckbox = this.otherCheckbox
                params.selectedCategoriesText = this.selectedCategoriesText
                return params
            },
            setSavedParams() {
                let disputeFilters = JSON.parse(sessionStorage.getItem('disputeFilterParams'))
                if (!!disputeFilters) {
                    if (Object.keys(disputeFilters).length) {
                        this.filter.page = disputeFilters.page
                        this.filter.perPage = disputeFilters.perPage
                        this.filter.email = disputeFilters.email
                        this.filter.invoice = disputeFilters.invoice
                        this.filter.isResolved = disputeFilters.isResolved
                        this.filter.hasActiveTasks = disputeFilters.hasActiveTasks
                        this.selectedCategories = disputeFilters.categories
                        this.pagData.currentPage = disputeFilters.page
                        this.brokenCheckbox = disputeFilters.brokenCheckbox
                        this.returnCheckbox = disputeFilters.returnCheckbox
                        this.retournedCheckbox = disputeFilters.retournedCheckbox
                        this.notArrivedCheckbox = disputeFilters.notArrivedCheckbox
                        this.differentCheckbox = disputeFilters.differentCheckbox
                        this.otherCheckbox = disputeFilters.otherCheckbox
                        this.selectedCategoriesText = disputeFilters.selectedCategoriesText
                    }
                }
            setTimeout(() => {
            	if (!!this.$route.params.filterTasks) {
		            this.filter.hasActiveTasks = true
		            delete this.filter.isResolved
                }
				this.getGroups()
			}, 0)
            this.selectedCategoriesText = this.selectedCategoriesText ? this.selectedCategoriesText : this.activeLanguage.categories.title
            }
		},
		mounted() {
			this.setSavedParams()
		},
		components: {
			Datepicker,
			ClientItemTemplate,
            Pagination,
            PaginationCount
		},
		computed: {
			...mapGetters({
                paginationLanguage: 'getOrdersLanguage',
				manageOrdersPermissions: 'userManage_orders',
                activeLanguage: 'getDisputeGroupsLanguage'
			}),
		},
	}
</script>

<style scoped lang="scss">
    .btns {
        justify-content: flex-end;
        @media (max-width: 1300px) {
            a {
                padding: 0.5rem 1.70rem;
            }
        }
        @media (max-width: 1200px) {
            justify-content: center;
            margin-top: 20px;
            a {
                margin: 0 5px 0;
            }
        }
    }
    .fa-check-circle-o {
        color: #69B3AE;
    }
    .fa-clock-o {
        color: #E7913E;
    }
    .fa-bell {
        color: #e7d534;
    }
    th,td {
        vertical-align: middle;
        text-align: left;
    }
    .half {
        pointer-events: none;
        opacity: 0.5;
    }
    .resolved {
        background: #ccf0ef;
    }
    .cord-body .col-6{
        margin-top: 6px;
        margin-bottom: 5px;
    }
    .table .thead-dark th {
        background-color: #032438;
    }
    @media (max-width: 1320px) {
        h1 {
            text-align: center;
            justify-content: center;
        }
        table {
            table-layout:fixed;
            th:first-child {
                width: 40px;
            }
        }
        .overflow {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100px;
        }
    }
</style>

<style scoped>
    >>> .b-dropdown .btn.btn-secondary {
        padding: 0.65rem 2rem 0.5rem 2rem;
        border-radius: .25rem !important;
        background: #4db5af !important;
        color: #ffffff !important;
        border-color: #4db5af !important;
    }
    >>> .dropdown-toggle:active {
        border-color: #4db5af !important;
    }
    >>> .dropdown-menu {
        padding: 0;
    }
    >>> .dropdown-menu .dropdown-item:active a,
    >>> .dropdown-menu .dropdown-item:focus a,
    >>> .dropdown-menu .dropdown-item:hover a {
        background: #4db5af !important;
        color: #ffffff !important;
    }
    >>> .dropdown-menu .dropdown-item:active,
    >>> .dropdown-menu .dropdown-item:focus,
    >>> .dropdown-menu .dropdown-item:hover {
        background: #4db5af !important;
        color: #ffffff !important;
    }

    .multiselect-custom {
        margin-top: auto;
        margin-bottom: auto;
        min-height: 0;
        width: auto;
    }
    .selectBox {
        position: relative;
        width: 200px;
    }

    .selectBox select option {
        width: 100%;
        font-weight: bold;
        border: none;
        text-decoration: underline;
        font-size: 14px;
    }
    /* .checkboxesInner label:hover {
        background: #009a97;
    } */
    .overSelect {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .checkboxesInner {
        background-color: #fff;
        border: 1px #dadada solid;
        width: 200px;
        display: flex;
        flex-direction: column;
    }
    .checkboxesInner label input {
        margin-right: 5px;
        margin-left: 15px;
    }
    .selected {
        background: #ccf0ef !important;
    }

</style>