<template>
    <ul class="pagination justify-content-center" v-show="pagData.pageCount > 1">
        <li
            @click.prevent="pageNav('prev')"
            class="page-item"
        >
            <a class="page-link" href="#">{{ activeLanguage.pagPrev }}</a>
        </li>
        <template v-if="pagData.pagRange.min > 1">
            <li class="page-item" @click.prevent="toToPage(1)">
                <a class="page-link">1</a>
            </li>
            <li class="page-item" style="pointer-events: none">
                <a class="page-link">...</a>
            </li>
        </template>
        <li
            class="page-item"
            v-for="page in pagData.pagRange.max"
            :class="page === pagData.pageCount ? 'active' : ''"
            @click.prevent="toToPage(page)"
        >
            <a class="page-link" href="#">{{ page }}</a>
        </li>
        <template v-if="pagData.pagRange.max >= 5">
            <li class="page-item" style="pointer-events: none">
                <a class="page-link">...</a>
            </li>
            <li class="page-item">
                <a @click.prevent="toToPage(pagData.maxPage)" class="page-link" href="#">{{ pagData.maxPage }}</a>
            </li>
        </template>
        <li
            @click.prevent="pageNav('next')"
            class="page-item"
        >
            <a class="page-link" href="#">{{ activeLanguage.pagNext }}</a>
        </li>
    </ul>
</template>

<script>
	export default {
		name: "Pagination",
		props: {
			activeLanguage: {
				type: Object,
				required: true
			},
            pagData: {
	            type: Object,
	            required: true
            }
		},
        data() {
			return {

            }
        },
        methods: {
	        pageNav(nav) {
		        if (nav === 'next') {
			        this.pagData.currentPage = this.pagData.currentPage < this.pagData.pageCount ? ++this.pagData.currentPage : this.pagData.currentPage;
		        } else if (nav === 'prev') {
			        this.pagData.currentPage = this.pagData.currentPage > 1 ? --this.pagData.currentPage : this.pagData.currentPage;
		        }
		        this.toToPage(this.pagData.currentPage)
	        },
	        toToPage(page) {
	        	this.$emit('goToPage', page)
            }
        }
	}
</script>

<style scoped>

</style>