<template>
    <div class="clientItem">
        <div>
            <div>
                {{item.email || item.invoice }}
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: "DisputeAutocompleteItem",
		props: {
			item: { required: true },
			searchText: { required: true }
		}
	}
</script>

<style lang="scss">
    .clientItem {
        &>div {
            padding: 5px 10px;
            div:first-child {
                font-size: 18px;
            }
        }
    }
    .v-autocomplete {
        z-index: 100;
    }
    .v-autocomplete-list {
        width: 100%;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        &-item {
            border-bottom: 1px solid #ced4da;
            &:last-child {
                border-bottom: none;
            }
        }
    }
</style>