<template>
    <div class="pagCount">
        {{ pageCount }}
    </div>
</template>

<script>
	export default {
		name: "PaginationCount",
        props: {
	        pagData: {
	            required: true,
	            type: Object,
	            default: function () {
                    return {}
	            }
            },
	        activeLanguage: {
		        required: true,
		        type: Object,
		        default: function () {
			        return {}
		        }
	        },
	        selection: {
		        required: true,
		        type: Array,
		        default: function () {
			        return []
		        }
            }
        },
        computed: {
			pageCount() {
                let perPage = this.pagData.perPage ? this.pagData.perPage : 20 
				let orderCount = `${1 + this.pagData.currentPage * perPage - perPage} - ${this.pagData.currentPage * perPage}`,
                    count = `${orderCount} ${this.activeLanguage.of} ${this.pagData.maxItems} ${this.activeLanguage.results}`

                if (this.pagData.maxItems < 21) {
					count = `${this.pagData.maxItems} ${this.activeLanguage.of} ${this.pagData.maxItems} ${this.activeLanguage.results}`
                }

				if (!!this.selection.length) {
					count = `${this.selection.length} ${this.activeLanguage.selected} ${this.activeLanguage.of} ${this.pagData.maxItems} ${this.activeLanguage.results}`
				}

				return count
            }
        }
	}
</script>

<style scoped lang="scss">
    .pagCount {
        position: relative;
        top: -42px;
        left: 0px;
        font-weight: 600;
        font-size: 13.5px;
        z-index: 1;
        width: 280px;
        text-overflow: ellipsis;
        @media (max-width:1399px) {
            position:static;
        }
        @media (max-width:997px) {
            width: 100%;
            text-align: center;
        }
    }
</style>